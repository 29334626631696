import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import messages from "./messages";
import { useIntl } from "react-intl";
import theme from "@/theme/theme";

const HeaderCard = () => {
  const { formatMessage: __ } = useIntl();

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-80px",
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "650px",
          backgroundColor: "#F9F9F9",
          p: 1.5,
          display: "flex",
          alignItems: "center",
          gap: 2,
          borderRadius: 2,
        }}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          color={theme.palette.primary.main}
          style={{
            width: "40px",
            height: "40px",
          }}
        />

        <Typography variant="body1" sx={{ width: "90%", fontSize: "14px" }}>
          {__(messages.newVersionDescription)}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderCard;
