import { styled } from "@mui/material/styles";
import {
  LinearProgress as MuiLinearProgress,
  linearProgressClasses,
  Typography,
  Box,
  Button as MuiButton,
  Chip,
  TextField,
} from "@mui/material";
import theme from "@/theme/theme.tsx";

export const LinearProgress = styled(MuiLinearProgress)({
  ["&"]: {
    height: "8px",
    background: "white",
    [`& .${linearProgressClasses.bar}`]: {
      background: "linear-gradient(to right, #2D5BFF, #5C2DD3)",
    },
  },
});

export const Title = styled(Typography)({
  ["&"]: {
    background: "linear-gradient(to right, #2D5BFF, #5C2DD3)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginBottom: "10px",
  },
});

export const Header = styled(Box)({
  ["&"]: {
    width: "100%",
    textAlign: "center",
    marginBottom: "35px",
  },
});

export const SubTitle = styled(Typography)({
  ["&"]: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0.15px",
    width: "349px",
    marginLeft: "calc((100% - 349px) / 2)",
  },
});

export const SubTitle1 = styled(Typography)({
  ["&"]: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "4px",
  },
});

export const SubTitle2 = styled(Typography)({
  ["&"]: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0.15px",
    marginBottom: "8px",
    fontStyle: "italic",
  },
});

export const DialogActions = styled(Box)({
  ["&"]: {
    padding: "40px 30px",
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const Button = styled(MuiButton)({
  ["&"]: {
    color: "black",
    textTransform: "capitalize !important",
    fontWeight: 600,
  },
});

export const ChipContent = styled(Box)({
  width: "100%",
  maxWidth: "100%",
  minHeight: "calc(100% - 10px)",
  height: "calc(100% - 10px)",
  maxHeight: "calc(100% - 10px)",
  display: "flex",
  flexWrap: "wrap",
  gap: "5px",
  alignContent: "flex-start",
  overflow: "hidden",
  marginBottom: "15px",
});

export const CustomChip = styled(Chip)({
  padding: "5px 5px 5px 0px",
  color: "black",
  backgroundColor: "#F6F6F6",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "4px",
  height: "30px",
  border: "1px solid #0000001A",
  "& .MuiChip-label": {
    padding: "0px 5px 0px 0px",
    textTransform: "lowercase",
    fontWeight: 500,
  },
  "& .MuiChip-label:first-letter": {
    textTransform: "capitalize !important",
  },
});

export const OtpBox = styled(Box)({
  ["&"]: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "rgba(249, 249, 249, 1)",
    color: "black",
    width: "100%",
    height: "213px",
    borderRadius: "4px",
    marginTop: "10px",
    padding: "15px 30px",
  },
});

export const Description = styled(Typography)({
  ["&"]: {
    color: "black",
    fontSize: "14px",
    width: "440px",
  },
});

export const Phone = styled("span")({
  ["&"]: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
});

export const Code = styled(Box)({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 0px 0px",
    width: "350px",
  },
});

export const CodeTextField = styled(TextField)({
  ["&"]: {
    width: "40px",
    height: "40px",
    background: "white",
    ".MuiInputBase-input": {
      fontSize: "18px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "center",
    },
  },
});

export const ResendCodeBox = styled(Box)({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0px 20px 0px",
    width: "350px",
  },
});

export const ResendCode = styled("span")<{ disabled?: boolean }>(({ disabled }) => ({
  ["&"]: {
    color: disabled ? "grey" : theme.palette.primary.main,
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: disabled ? "default" : "pointer",
  },
}));

export const OtherButton = styled(MuiButton)({
  ["&"]: {
    border: "1px solid rgba(92, 45, 211, 1)",
    background: "rgba(92, 45, 211, 0.1)",
    color: "rgba(92, 45, 211, 1)",
    textTransform: "capitalize !important",
    fontWeight: 600,
    borderRadius: "4px",
    height: "30px",
    padding: "0px 12px",
  },
});
