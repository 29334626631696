import { ApiEndpointBuilder } from "@/services/api/types";

const getEnterpriseByIdCommercial = (builder: ApiEndpointBuilder) => {
  return builder.query<EnterpriseCommercialProfile[], { enterpriseTenantId: string }>({
    query: ({ enterpriseTenantId }) => ({
      url: `/enterprises/${enterpriseTenantId}/contacts`,
      method: "GET",
    }),
    providesTags: ["Enterprise"],
  });
};

export type EnterpriseCommercialProfile = {
  jobTitle: string | null;
  phone1: string | null;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string | null;
};
export default getEnterpriseByIdCommercial;
