import { fetchAccessToken } from "@/config/msalConfig";
import { ServerResponseUsers } from "@/store/users/types";
import {
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";

export const API_TagTypes = [
  "Document",
  "Equipment",
  "Rooms",
  "Events",
  "Event",
  "ContentFile",
  "Certificates",
  "KanbanProjects",
  "kanbanTasks",
  "kanbanTask",
  "kanbanTags",
  "kanbanComments",
  "knowledgeBases",
  "knowledgeBase",
  "kanbanFiles",
  "newsArticles",
  "announcements",
  "Employee",
  "currentEmployee",
  "ContentFile",
  "Article",
  "feedbacks",
  "feedback",
  "persoliteTags",
  "storeProduct",
  "persoliteDirectory",
  "persoliteDocument",
  "persoliteZip",
  "persoliteShareDirectory",
  "Enterprise",
  "KanbanReaction",
  "newsAnnouncement",
  "user",
  "communes",
  "Referral",
  "enterpriseReviews",
  "documentSignatures",
  "talkJS",
  "kanbanStatusChanged",
  "BusinessMapStatistics",
  "InfiniteEmployeesQuery",
  "enterpriseProfile",
  "KanbanProject",
  "KanbanTasks",
  "usersInvitationsList",
  "partnershipsRequests",
  "partnershipsCategories",
  "partnerships",
  "KanbanGroups",
  "KanbanGroup"
] as const;

export const api = createApi({
  reducerPath: "api",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: import.meta.env.VITE_APP_API_ENDPOINT,
      prepareHeaders: async (headers, { getState }) => {
        const {
          tenant: { tenantId },
        } = getState() as any;

        const accessToken = await fetchAccessToken();

        headers.set("Accept", "application/json");
        if (accessToken) {
          headers.set("Authorization", `Bearer ${accessToken.token}`);
        }

        if (tenantId) {
          headers.set("x-tenant", tenantId);
        }
        return headers;
      },
    }),
    {
      retryCondition: (_error, _args, { attempt }) => attempt < 2,
    }
  ),
  tagTypes: API_TagTypes,
  endpoints: () => ({}),
});

export const usersApiSlice = createApi({
  reducerPath: "userApi",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: import.meta.env.VITE_APP_API_ENDPOINT,
      prepareHeaders: async (headers, { getState }) => {
        const {
          tenant: { tenantId },
        } = getState() as any;

        const accessToken = await fetchAccessToken();
        headers.set("Accept", "application/json");
        if (accessToken) {
          headers.set("Authorization", `Bearer ${accessToken.token}`);
        }

        if (tenantId) {
          headers.set("x-tenant", tenantId);
        }
        return headers;
      },
    }),
    {
      retryCondition: (error: FetchBaseQueryError) => {
        if (error.status === 401 || error.status === 500) return true;
        return false;
      },
    }
  ),
  tagTypes: [
    "Document",
    "Equipment",
    "Rooms",
    "Events",
    "Certificates",
    "KanbanProjects",
    "kanbanTasks",
    "kanbanTask",
    "kanbanTags",
    "kanbanComments",
    "knowledgBases",
    "kanbanFiles",
    "newsArticles",
    "announcements",
    "Employee",
    "currentEmployee",
    "enterpriseProfile",
  ], // This is most likely not what you're looking for, see Line 10 instead
  endpoints: builder => ({
    getUsers: builder.query({
      query: () => ({
        url: `${import.meta.env.VITE_APP_API_ENDPOINT}/users`,
      }),
      transformResponse: (response: ServerResponseUsers) => response,
    }),
  }),
});

export const typesenseApi = createApi({
  reducerPath: "typesenseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_APP_TYPESENSE_API_ENDPOINT,
    prepareHeaders: async headers => {
      const accessToken = await fetchAccessToken();
      headers.set("Accept", "application/json");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken.token}`);
        headers.set("X-TYPESENSE-API-KEY", import.meta.env.VITE_APP_TYPESENSE_API_KEY as string);
      }
      return headers;
    },
  }),
  tagTypes: ["EmployeeSearch", "EnterpriseSearch", "AllSearch"],
  endpoints: () => ({}),
});
