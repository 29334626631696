export const findAdjacentStep = (
    arr_step: number[],
    value: number,
    direction: "next" | "previous"
): number | undefined => {
    const index = arr_step.indexOf(value);

    if (index === -1) {
        return undefined;
    }

    // For 'next' element, return index + 1; for 'previous', return index - 1
    if (direction === "next" && index < arr_step.length - 1) {
        return arr_step[index + 1];
    } else if (direction === "previous" && index > 0) {
        return arr_step[index - 1];
    }

    return undefined;
};