import { ApiEndpointBuilder } from "@/services/api/types";
import { Profile } from "@/types/profile";
import { convertToLocalTime } from "@/utils/date/dayjs.config";

const getCurrentEmployee = (builder: ApiEndpointBuilder) => {
  return builder.query<Profile, void>({
    query: () => ({
      url: `/enterpriseemployees/current`,
      method: "GET",
    }),
    transformResponse: (userData: Profile) => ({
      ...userData,
      picture: userData.picture && {
        ...userData.picture,
        azureBlobUri: userData?.picture?.azureBlobUri
          ? `${userData?.picture?.azureBlobUri}?t=${new Date().getTime()}`
          : userData?.picture?.azureBlobUri,
      },
      activityZones: userData.activityZones.map(zone => ({
        ...zone,
        fullCountryCode: zone.countryCode === "AE" ? "UAE" : zone.countryCode,
      })),
      dateOfBirth: convertToLocalTime(userData.dateOfBirth),
      dateOfIntegration: convertToLocalTime(userData.dateOfIntegration),
    }),
    providesTags: ["currentEmployee"],
  });
};
export default getCurrentEmployee;
