import { ApiEndpointBuilder } from "@/services/api/types";
import { Enterprise } from "@/types/store/profile";
import { convertToLocalTime } from "@/utils/date/dayjs.config";

const getCurrentEnterprise = (builder: ApiEndpointBuilder) => {
  return builder.query<Enterprise, any>({
    query: () => ({
      url: `/enterprises/current`,
      method: "GET",
    }),
    transformResponse: (enterpriseData: Enterprise) => ({
      ...enterpriseData,
      enterprisePictures: enterpriseData.enterprisePictures?.map(picture => ({
        ...picture,
        azureBlobUri: picture?.azureBlobUri
          ? `${picture?.azureBlobUri}?t=${new Date().getTime()}`
          : picture?.azureBlobUri,
      })),
      legalInformation: {
        ...enterpriseData.legalInformation,
        creationDate: convertToLocalTime(
          (enterpriseData.legalInformation?.creationDate as unknown as string) ?? ""
        ) as unknown as Date, // satisfying type
      },
    }),
    providesTags: ["Enterprise"],
  });
};
export default getCurrentEnterprise;
