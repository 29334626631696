import { useGetCurrentEmployeeQuery } from "@/store/employee/endpoints";

const useCurrentUserHook = () => {
  const { data: currentEmployee, isLoading } = useGetCurrentEmployeeQuery(undefined, {
    skip: location.pathname.includes("logout-session"),
  });

  if (isLoading)
    return {
      isLoadingUser: isLoading,
      openUserModal: false,
    };

  const hasEnterpriseRecords =
    (currentEmployee?.enterpriseRecords?.length === 0 &&
      (currentEmployee?.enterprises ?? []).length > 0 &&
      !currentEmployee?.enterprises?.some(enterprise => enterprise.title === "Company Name")) ||
    (currentEmployee?.enterpriseRecords ?? []).length > 0;
  const hasActivityDomain = currentEmployee?.activityDomain;
  const hasSubActivityDomain = (currentEmployee?.subActivityDomains ?? []).length > 0;
  const hasJobTitle = currentEmployee?.jobTitle;
  const hasSpecialities = (currentEmployee?.specialities ?? []).length > 0;
  const hasValidPhoneNumber = currentEmployee?.phone1Valid;

  const openUserModal = Boolean(
    !(
      hasEnterpriseRecords &&
      hasActivityDomain &&
      hasSubActivityDomain &&
      hasJobTitle &&
      hasSpecialities &&
      hasValidPhoneNumber
    )
  );
  return { openUserModal };
};

export default useCurrentUserHook;
