import { ApiEndpointBuilder } from "@/services/api/types";
import { Enterprise } from "@/types/store/profile";

const getEnterpriseById = (builder: ApiEndpointBuilder) => {
  return builder.query<Enterprise, string>({
    query: id => ({
      url: `/enterprises/${id}`,
      method: "GET",
    }),

    providesTags: ["Enterprise", "enterpriseReviews"],
  });
};
export default getEnterpriseById;
