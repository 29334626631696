import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.tsx";
import { store, persistor, persistConfig } from "./store/index.ts";
import { registerLicense } from "@syncfusion/ej2-base";
import { PersistGate } from "redux-persist/integration/react";
import { APIProvider } from "@vis.gl/react-google-maps";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { msalInstance } from "./config/msalConfig.ts";
import { TenantsType } from "./store/tenants/tenantsSlice.ts";
import * as Sentry from "@sentry/react";

// Configure PDF.js worker
(async () => {
  const { pdfjs } = await import("react-pdf");
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();
})();

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://web.api.dev.ila26.fr/api/", "https://web.api.staging.ila26.fr/api/", "https://web.api.ila26.fr/api/"],
  // Session Replay
  replaysSessionSampleRate: import.meta.env.VITE_APP_SENTRY_SAMPLING_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();


  msalInstance.addEventCallback(event => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      (event?.payload as AuthenticationResult).account
    ) {
      msalInstance.setActiveAccount((event?.payload as AuthenticationResult).account);
    }
  });

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE || event.eventType === EventType.ACTIVE_ACCOUNT_CHANGED) {
      // clear redux persisted state if MSAL token expires
      localStorage.setItem(
        `persist:${persistConfig.key}`,
        JSON.stringify({
          shouldSelectTenant: false,
          tenantId: undefined,
          tenantsList: undefined,
          userId: undefined,
        } as TenantsType)
      );
      window.location.reload();
    }
  });

  // Registering Syncfusion license key
  registerLicense(import.meta.env.VITE_APP_SYNCFUSION_LICENSE_KEY);

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <APIProvider apiKey={import.meta.env.VITE_APP_GOOGLE_MAPS_KEY} libraries={["places"]}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* @ts-ignore */}
            <App instance={msalInstance} />
          </PersistGate>
        </Provider>
      </APIProvider>
    </React.StrictMode>
  );
});
