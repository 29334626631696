import toast from "react-hot-toast";

const recurrenceRuleRegexes: ("FREQ" | "INTERVAL" | "UNTIL" | "COUNT")[] = [
  "FREQ",
  "INTERVAL",
  "UNTIL",
  "COUNT",
];

export const recurrenceRuleToJSObject = (rule: string) => {
  // DEPRECATED
  const recurrenceRule: {
    recurring: boolean;
    recurrenceFrequency: number;
    recurrencyStop: number;
    recurrenceStopDate: string | null;
  } = {
    recurring: false,
    recurrencyStop: 0,
    recurrenceFrequency: 0,
    recurrenceStopDate: null,
  };

  recurrenceRuleRegexes.forEach(regex => {
    const regEx = new RegExp(regex, "g");
    const isRuled = regEx.test(rule);

    if (isRuled) {
      recurrenceRule.recurring = true;
      const newRegex = new RegExp(`${regex}=([^;]+)`);
      const retained = rule.match(newRegex)?.[1];

      if (retained && regex === "FREQ")
        recurrenceRule.recurrenceFrequency =
          retained === "DAILY" ? 0 : retained === "WEEKLY" ? 1 : retained === "MONTHLY" ? 2 : 3;

      // if(retained && regex==="INTERVAL")
      if (retained && regex === "UNTIL") {
        recurrenceRule.recurrencyStop = 1;
        const dateRetained = retained.split("T")[0];
        const timeRetained = retained.split("T")[1];

        const parsedTime = [
          ...timeRetained.slice(0, 2),
          ":",
          ...timeRetained.slice(2, 4),
          ":",
          ...timeRetained.slice(4, 6),
        ].join("");

        const parsedDate = [
          ...dateRetained.slice(0, 4),
          "-",
          ...dateRetained.slice(4, 6),
          "-",
          ...dateRetained.slice(6, 8),
        ].join("");

        recurrenceRule.recurrenceStopDate = `${parsedDate}T${parsedTime}.000Z`;
      }
    }
  });

  return recurrenceRule;
};

export const flattenObject = (obj: Record<string, any>, parentKey = ""): Record<string, any> => {
  let result: Record<string, string> = {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const nestedObject = flattenObject(obj[key], newKey);
        result = { ...result, ...nestedObject };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};

export const getTemplate = (name?: string, link?: string) => {
  return `
    <div style="backgroundColor: #F9F9F9; padding: 20px;">
      <div style="margin-bottom: 12px;">Bonjour,</div>
      <br/>
      <div>
        L’utilisateur <b>${name}</b> vous invite a rejoindre ila26,<br/>
      </div>  
      <br/>
      <div style="lineHeight: 18px;">
        Cliquez sur ce lien pour accepter votre invitation:  
        <a 
          href="${link}"
          target="_blank"
          style=" cursor: pointer;"
        >
          <div style="textDecoration: underline; fontWeight: 600; color: #3A167F; display: inline">
            ${link}
          </div>
        </a> 
        <br/>
        <i><b style="fontSize: 12px;">Veuillez noter que la durée de validité du lien est de 48H.</b></i>
      </div>
      <br/>
      <div>
        On est <b>impatients</b> de vous voir sur <span style="color: #3A167F; fontWeight: 600">ila26</span>.  
      </div> 
      <br/>
      <span style="color: #3A167F">L’équipe <b>ila26.</b></span>
      <br/>
      <br/>
    </div>
  `;
};

export const copyFromCodeToClipboard = async (
  partToCopy: HTMLAreaElement | null,
  onDone: any,
  template: string,
  linkToInsert?: string
) => {
  if (partToCopy) {
    const range = document.createRange();
    range.selectNode(partToCopy);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);

    const innerText = linkToInsert
      ? partToCopy.innerText.replace("Lien", linkToInsert)
      : partToCopy.innerText;
    const outerHTML = template;

    const clipboardItemInput = new ClipboardItem({
      "text/plain": new Blob([innerText], { type: "text/plain" }),
      "text/html": new Blob([outerHTML], { type: "text/html" }),
    });

    navigator.clipboard
      .write([clipboardItemInput])
      .then(() => {
        onDone();
        window.getSelection()?.removeAllRanges();
      })
      .catch(e => {
        console.error(e);
      });
  }
};

export const capitalizeText = (text: string) => {
  const firstLetter = text.at(0);
  return `${firstLetter?.toUpperCase()}${text.slice(1).toLowerCase()}`;
};

export const getDateDifference = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  if (!startDate) return;
  const _endDate = endDate ? new Date(endDate) : new Date();
  const _startDate = new Date(startDate);

  const differenceMs = Math.abs(_endDate.valueOf() - _startDate.valueOf());

  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const differenceMonths =
    _endDate.getMonth() -
    _startDate.getMonth() +
    12 * (_endDate.getFullYear() - _startDate.getFullYear());
  const differenceYears = _endDate.getFullYear() - _startDate.getFullYear();
  const differenceHours = Math.floor(differenceMs / (1000 * 60 * 60));
  const differenceMinutes = Math.floor(differenceMs / (1000 * 60));

  return {
    minutes: differenceMinutes,
    hours: differenceHours,
    days: differenceDays,
    months: differenceMonths,
    years: differenceYears,
  };
};

export const copyTextToClipboard = async (text: string, message: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success(message);
    })
    .catch(err => {
      console.error("Failed to copy text: ", err);
    });
};
