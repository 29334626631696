import { useState } from "react";
import { Box, Autocomplete, TextField, Typography, TextFieldProps } from "@mui/material";
import { useIntl } from "react-intl";
import usePlacesAutoComplete, { getDetails, getGeocode } from "use-places-autocomplete";
import messages from "./messages";
import theme from "@/theme/theme";
import { faMapLocation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Coordinates {
  lat: number | undefined;
  lng: number | undefined;
}

interface Location {
  zipCode: string | undefined;
  city: string;
  country: string;
  countryCode: string;
  departement: string | undefined;
  address: string;
  coordinates: Coordinates;
}

interface PickerProps {
  onChange: (location: Location) => void;
  onClear?: () => void; // New prop for clear functionality
  label?: string;
  placeholder?: string;
  initialValue?: string;
  disableHelperTxt?: boolean;
  errorText?: string;
  inputName?: string;
  forceClearValue?: string;
  InputProps?: TextFieldProps["InputProps"];
}

const DEFAULT_LOCATION: Location = {
  zipCode: undefined,
  departement: undefined,
  address: "",
  city: "",
  country: "",
  countryCode: "",
  coordinates: {
    lat: undefined,
    lng: undefined,
  },
};

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const Picker: React.FC<PickerProps> = ({
  onChange,
  onClear,
  label,
  placeholder,
  initialValue,
  disableHelperTxt,
  errorText,
  inputName,
  forceClearValue,
  InputProps,
}) => {

  const { formatMessage: __ } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    ready,
    suggestions: { data, status: suggestionsStatus },
    setValue,
    value,
    clearSuggestions,
  } = usePlacesAutoComplete({
    requestOptions: {
      language: "en",
    },
    debounce: 300,
  });


  const processAddressComponent = (addressComponents: AddressComponent[]): Partial<Location> => {
    const location: Partial<Location> = {};

    addressComponents.forEach(component => {
      const { types, long_name, short_name } = component;

      if (types.includes("country")) {
        location.country = long_name;
        location.countryCode = short_name;
      }
      if (types.includes("locality")) {
        location.city = short_name;
      }
      if (types.includes("postal_code")) {
        location.zipCode = short_name;
      }
      if (types.includes("administrative_area_level_2")) {
        location.departement = short_name;
      }
    });

    return location;
  };

  const fetchPlaceDetails = async (address: string) => {
    if (!data.length) return;

    setIsLoading(true);
    setError(null);

    try {
      const selectedPlace = data.find(place => place.description === address);
      if (!selectedPlace) {
        throw new Error("Selected place not found in suggestions");
      }

      const [placeDetailsResult, geocodingResult] = await Promise.all([
        getDetails({
          placeId: selectedPlace.place_id,
          fields: ["address_components"],
        }),
        getGeocode({
          placeId: selectedPlace.place_id,
        }),
      ]);

      if (typeof placeDetailsResult === "string" || !placeDetailsResult.address_components) {
        throw new Error("Invalid place details response");
      }

      const addressComponents = processAddressComponent(
        placeDetailsResult.address_components as AddressComponent[]
      );

      const location: Location = {
        ...DEFAULT_LOCATION,
        ...addressComponents,
        address,
        coordinates: {
          lat: geocodingResult[0]?.geometry?.location?.lat(),
          lng: geocodingResult[0]?.geometry?.location?.lng(),
        },
      };

      onChange(location);
    } catch (error) {
      setError(error instanceof Error ? error.message : "Failed to fetch location details");
      console.error("Error fetching place details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setError(null);
  };

  
  return (
    <Box>
      {!value && !disableHelperTxt && (
        <Typography
          fontWeight={200}
          variant="subtitle1"
          color={theme.palette.grey[700]}
          sx={{ mb: 1 }}
        >
          {__(messages.helperTitle)}
        </Typography>
      )}

      <Autocomplete
        key={forceClearValue}
        disabled={!ready || isLoading}
        filterOptions={x => x}
        size="small"
        defaultValue={initialValue}
        freeSolo
        disableClearable={false}
        onChange={async (_e, newAddress, reason) => {
          if (reason === "clear") onClear?.();
          if (newAddress) await fetchPlaceDetails(newAddress);
        }}
        onClose={() => clearSuggestions()}
        options={suggestionsStatus === "OK" ? data.map(({ description }) => description) : []}
        loading={isLoading}
        renderInput={params => (
          <TextField
            error={!!error || !!errorText}
            helperText={error || errorText}
            placeholder={placeholder ?? label?.toLowerCase() ?? ""}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: "4px",
            }}
            name={inputName}
            label={label ?? "Location"}
            {...params}
            value={value}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
              sx: {
                fontWeight: 600,
              },
            }}
            InputProps={{
              ...params.InputProps,
              ...(InputProps
                ? InputProps
                : {
                    type: "search",
                    endAdornment: (
                      <FontAwesomeIcon icon={faMapLocation} color={theme.palette.grey[300]} />
                    ),
                  }),
            }}
            FormHelperTextProps={{
              sx: {
                fontSize: "12px",
                fontWeight: 400,
                color: theme.palette.error.main,
                marginLeft: 0,
              },
            }}
          />
        )}
        sx={{
          "& .MuiInputBase-input": {
            height: "21px",
          },
          "& .MuiOutlinedInput-root": {
            paddingRight: "14px !important",
          },
        }}
      />
    </Box>
  );
};

export const LocationPicker: React.FC<PickerProps> = props => (
  <Box>
    <Picker {...props} />
  </Box>
);

export default LocationPicker;
