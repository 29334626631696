import { AbilityBuilder } from "@casl/ability";
import { appAbility } from ".";
import { ProfileResponse } from "@/types/profile";
import { defineBasicAbility } from "@/casl/basicAbilities";

export const defineCommunityManagerAbility = (_user: ProfileResponse) => {
  const { can, rules } = new AbilityBuilder(appAbility);

  can("Post_As_Enterprise", ["Network"]);
  can(["Create", "Update", "Delete"], "Support");

  return rules;
};

//Manager && Basic
export const defineBM_Ability = (user: ProfileResponse) => {
  const B_Rules = defineBasicAbility(user);
  const M_Rules = defineCommunityManagerAbility(user);

  const combinedRules = [...B_Rules, ...M_Rules];

  return combinedRules;
};

export const M_Ability = (user: ProfileResponse) =>
  new appAbility(defineCommunityManagerAbility(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const BM_Ability = (user: ProfileResponse) =>
  new appAbility(defineBM_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });
