import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { FormikProps } from "formik";
import theme from "@/theme/theme.tsx";
import { useGetCurrentEnterpriseQuery } from "@/store/market-place/endpoints";
import { useSearchSpecialitiesQuery } from "@/store/specialities/endpoints";
import { groupDataByNestedKey } from "@/containers/filter-page-wrapper/utilities.ts";
import OutlinedReactSelect from "@components/react-select/outlined-react-select.tsx";
import { ActionMeta, MultiValue } from "react-select";
import { EnterpriseSpeciality } from "@/types/store/profile.ts";
import { OfferSpeciality } from "@/types/offers.ts";


interface Props {
  form: FormikProps<{
    specialitiesIds: OfferSpeciality[] | null | undefined;
  }>;
  id?: string; // `id` is present when updating an offer
  label: string;
  placeholder?: string;
}

const SpecialityField: React.FC<Props> = ({ form, id, label, placeholder }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { formatMessage: __ } = useIntl();
  const { data: enterpriseData } = useGetCurrentEnterpriseQuery({});
  const { data: specialtiesData } = useSearchSpecialitiesQuery(
    !id
      ? {
          pageNumber: 0,
          pageSize: 50,
          advancedFilter: {
            logic: "and",
            filters: [
              {
                operator: "eq",
                value: enterpriseData?.activityDomain?.id,
                field: "enterpriseSubActivityDomain.activityDomain.id",
              },
            ],
          },
        }
      : {},
    {
      skip: !enterpriseData?.activityDomain?.id,
    }
  );

  const handleChange = (
    _value: MultiValue<EnterpriseSpeciality>,
    actionMeta: ActionMeta<EnterpriseSpeciality>
  ) => {
    const { action, removedValue, option } = actionMeta;
    if (action === "select-option") {
      form.setFieldValue("specialitiesIds", [...(form.values.specialitiesIds ?? []), option]);
    } else if (action === "remove-value") {
      const updatedOptions = (form.values.specialitiesIds ?? []).filter(
        (selectedOption: { id: string }) => selectedOption.id !== removedValue?.id
      );
      form.setFieldValue("specialitiesIds", updatedOptions);
    } else if (action === "clear") {
      form.setFieldValue("specialitiesIds", []);
    }
  };

  const getPrevSpecialitiesData = () => {
    const specialityIds = form.values.specialitiesIds?.map(speciality => speciality.id) ?? [];

    const matchedSpecialities = specialtiesData?.data?.filter(speciality =>
      specialityIds.includes(speciality.id)
    );

    if (matchedSpecialities) {
      return matchedSpecialities;
    }

    return [];
  };

  return (
    <Box
      sx={{
        flex: "1 1 calc(50% - 5px)",
        maxWidth: "100%",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <OutlinedReactSelect
        label={label}
        placeholder={placeholder}
        value={
          (form.values?.specialitiesIds ?? [])
            .map((v: { id: string }) => specialtiesData?.data.find(sp => sp?.id === v?.id))
            .filter(Boolean) as EnterpriseSpeciality[]
        }
        getOptionValue={(option: EnterpriseSpeciality) => option.id}
        getOptionLabel={(option: EnterpriseSpeciality) => option.label}
        options={
          specialtiesData?.data
            ? groupDataByNestedKey(
                id
                  ? [
                      ...specialtiesData?.data.filter(
                        data =>
                          data.enterpriseSubActivityDomain.activityDomain.id ===
                          enterpriseData?.activityDomain?.id
                      ),
                      ...getPrevSpecialitiesData(),
                    ]
                  : specialtiesData?.data,
                "enterpriseSubActivityDomain.name"
              )
            : []
        }
        isMulti
        filterOption={(option, inputValue) => {
          return (
            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.data.enterpriseSubActivityDomain.name
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          );
        }}
        onChange={(value, action) => handleChange(value as EnterpriseSpeciality[], action)}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
      />
    </Box>
  );
};

export default SpecialityField;
