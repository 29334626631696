/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO-IMPORTANT: REFACTOR , SIMPLIFY AND OPTIMIZE THIS HOOK
import {
  Autocomplete as AutocompleteMui,
  Box,
  ButtonBase,
  Checkbox,
  CheckboxProps,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Select,
  Switch,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
  createFilterOptions,
  Theme,
} from "@mui/material";
import {
  Field,
  FieldInputProps,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  FormikTouched,
  FormikValues,
} from "formik";
import {
  useMemo,
  ChangeEvent,
  DragEvent,
  useRef,
  MutableRefObject,
  PropsWithChildren,
} from "react";
import {
  BooleanSchema,
  NumberSchema,
  StringSchema,
  object as yupObject,
  MixedSchema,
  ArraySchema,
  AnyObject,
  DateSchema,
  Schema,
} from "yup";

import { InputHTMLAttributes, useEffect, useState, useCallback, CSSProperties } from "react";
import InputWrapper from "@/components/input-wrapper";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import dayjs from "@/utils/date/dayjs.config";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import CustomRichTextEditor from "@/components/rich-text-editor";
import FileUploader from "@/components/FileUploader/FileUploader";
import TagsInput from "@/components/tags-input";
import { ContentFile } from "@/types/content-file";
import theme from "@/theme/theme";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faClose } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import messages from "./messages";
import React, { cloneElement } from "react";
import Autocomplete, { Option } from "@components/auto-complete";
import ReadOnlyDisplay from "@/components/read-only-display";
import { isRequiredField } from "@/utils/form-generator/validation";
import { withFormikDevtools } from "formik-devtools-extension";
import { Tooltip } from "@components/tooltip";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { PhoneOtpObject } from "@components/phone-country-input/types";
import PhoneCountryInput from "@components/phone-country-input";
import _ from "lodash";

const filter = createFilterOptions();

export const ErrorMessage = ({ children }: PropsWithChildren) => {
  return (
    <Typography variant="caption" color={theme.palette.error.main}>
      {children}
    </Typography>
  );
};

export type FormGenConfig = {
  [key: string]:
    | {
        type: "colorPicker";
        attributes?: never;
        validationSchema: (values: FormikValues) => StringSchema;
        labelText?: never;
        subLabelText?: never;
        initialValue?: string;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        onNewValue?: never;
        optionValue?: never;
        displayIntVales?: never;
        disable?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "date";
        attributes?: DatePickerProps<Date | null | undefined>;
        validationSchema: (
          values: FormikValues
        ) => DateSchema<Date | null | undefined, AnyObject, undefined, "">;
        labelText: string;
        subLabelText?: never;
        initialValue?: Date | null | undefined | string;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        onNewValue?: never;
        displayIntVales?: never;
        disable?: never;
        optionValue?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "datetime";
        attributes?: DateTimePickerProps<Date | Dayjs | null | undefined>;
        validationSchema: (
          values: FormikValues
        ) => DateSchema<Date | null | undefined, AnyObject, undefined, "">;
        labelText: string;
        subLabelText?: never;
        initialValue?: Date | null | undefined | string;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        onNewValue?: never;
        displayIntVales?: never;
        disable?: never;
        optionValue?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "text" | "password";
        attributes: TextFieldProps;
        validationSchema: (values: FormikValues) => StringSchema;
        labelText: string;
        subLabelText?: never;
        initialValue: string;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        onNewValue?: never;
        displayIntVales?: never;
        disable?: never;
        optionValue?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "textarea";
        attributes: TextFieldProps;
        validationSchema: (values: FormikValues) => StringSchema;
        labelText: string;
        subLabelText?: never;
        initialValue: string;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        displayIntVales?: never;
        disable?: never;
        onNewValue?: never;
        optionValue?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "number";
        attributes: InputHTMLAttributes<HTMLInputElement>;
        validationSchema: (values: FormikValues) => NumberSchema<number | null | undefined>;
        labelText: string;
        subLabelText?: never;
        initialValue: number | null;
        options?: never;
        optionLabel?: never;
        multi?: never;
        displayIntVales?: never;
        disable?: never;
        style?: CSSProperties;
        onNewValue?: never;
        optionValue?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "toggle";
        attributes: InputHTMLAttributes<HTMLInputElement>;
        validationSchema: (values: FormikValues) => BooleanSchema;
        labelText: string;
        subLabelText?: never;
        initialValue: boolean;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        onNewValue?: never;
        optionValue?: never;
        displayIntVales?: never;
        disable?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "multi";
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes: any; // ! make it Select and Autocomplete props later
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validationSchema: (values: FormikValues) => any; // ! make it ArraySchema later
        labelText: string;
        subLabelText?: never;
        initialValue: { [key: string]: string | number }[] | [];
        options: { [key: string]: string | number }[];
        optionLabel: string;
        optionValue: string;
        onNewValue?: (newValue: string) => Promise<string>;
        style?: CSSProperties;
        multi?: never;
        displayIntVales?: never;
        disable?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "select";
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes: any; // ! make it Select and Autocomplete props later
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validationSchema: (values: FormikValues) => any; // ! make it ArraySchema later
        labelText: string;
        subLabelText?: never;
        initialValue?: string | number; // the value not the text
        options: { text: string | number; value: string | number }[];
        optionLabel: string;
        multi?: never;
        style?: CSSProperties;
        onNewValue?: never;
        optionValue?: never;
        displayIntVales?: never;
        disable?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
        /**
         * @param onChange
         * This handler will be used in addition to the internal onChange which is setting field value in the form state
         */
        onChange?: (args: Omit<FieldProps, "meta">, value?: string) => void;
      }
    | {
        type: "hidden";
        attributes: InputHTMLAttributes<HTMLInputElement>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validationSchema: (values: FormikValues) => any;
        labelText: string;
        subLabelText?: never;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initialValue: any;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: never;
        onNewValue?: never;
        optionValue?: never;
        displayIntVales?: never;
        disable?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "file";
        attributes: InputHTMLAttributes<HTMLInputElement>;
        validationSchema: (values: FormikValues) => MixedSchema; // ! Implement data type to validation
        accepts: string[];
        labelText: string;
        subLabelText?: never;
        initialValue?: { name: string }[];
        options?: never;
        optionLabel?: never;
        multi: boolean;
        displayIntVales?: never;
        disable?: never;
        style?: never;
        onNewValue?: never;
        optionValue?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "file2";
        attributes: InputHTMLAttributes<HTMLInputElement>;
        validationSchema: (
          values: FormikValues
        ) => ArraySchema<(any | undefined)[] | undefined, AnyObject, "", ""> | MixedSchema;
        accepts: string[];
        labelText: string;
        subLabelText?: string;
        initialValue: string[] | ContentFile[];
        options?: never;
        optionLabel?: never;
        displayIntVales?: boolean;
        disable?: never;
        multi: boolean;
        style?: CSSProperties;
        onNewValue?: never;
        optionValue?: never;
        onDelete?: (id: string) => void;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: boolean;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "checkbox";
        attributes: CheckboxProps;
        validationSchema: (values: FormikValues) => BooleanSchema;
        labelText: string;
        subLabelText?: never;
        initialValue: boolean;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        displayIntVales?: never;
        disable?: never;
        onNewValue?: never;
        optionValue?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "radiogroup";
        attributes: RadioGroupProps;
        validationSchema: (values: FormikValues) => any;
        labelText: string;
        subLabelText?: never;
        initialValue?: string | number;
        options: { text: string | number; value: string | number }[];
        optionLabel: string;
        displayIntVales?: never;
        disable?: never;
        multi?: never;
        optionValue?: string;
        onNewValue?: never;
        style?: CSSProperties;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "richtexteditor";
        attributes?: never;
        validationSchema: (values: FormikValues) => StringSchema;
        labelText: string;
        subLabelText?: never;
        initialValue: string;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        displayIntVales?: never;
        disable?: never;
        onNewValue?: never;
        optionValue?: never;
        accepts?: never;
        onDelete?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        content?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "tags";
        attributes: TextFieldProps;
        validationSchema: (values: FormikValues) => ArraySchema<
          | {
              text?: string | undefined;
            }[]
          | undefined,
          AnyObject,
          "",
          ""
        >;
        labelText: string;
        subLabelText?: never;
        initialValue: { text: string }[];
        options?: never;
        optionLabel?: never;
        optionValue?: never;
        onNewValue?: never;
        style?: CSSProperties;
        accepts?: never;
        displayIntVales?: never;
        disable?: never;
        multi?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "label";
        attributes?: never;
        validationSchema?: never;
        labelText?: string;
        subLabelText?: never;
        initialValue?: never;
        options?: never;
        optionLabel?: never;
        optionValue?: never;
        onNewValue?: never;
        style?: CSSProperties;
        accepts?: never;
        displayIntVales?: never;
        disable?: never;
        multi?: never;
        onDelete?: never;
        onClose?: never;
        onOpen?: never;
        content: string | JSX.Element;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      }
    | {
        type: "customComponents";
        attributes?: never;
        labelText?: never;
        subLabelText?: never;
        options?: never;
        optionLabel?: never;
        optionValue?: never;
        onNewValue?: never;
        accepts?: never;
        displayIntVales?: never;
        disable?: never;
        multi?: never;
        onClose?: never;
        onOpen?: never;
        onDelete?: never;
        style?: CSSProperties;
        validationSchema?:
          | ArraySchema<{ text?: string | undefined }[] | undefined, AnyObject, "", "">
          | MixedSchema
          | StringSchema
          | BooleanSchema
          | NumberSchema
          | any;
        initialValue?: any;
        content: (arg: { form: FormikProps<any>; field: FieldInputProps<any> }) => JSX.Element;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: boolean;
        onReadOnlyDisplay?: (arg: { form: FormikProps<any> }) => JSX.Element;
      }
    | {
        type: "autocomplete";
        attributes?: any;
        validationSchema: (values: FormikValues) => any;
        labelText: string;
        subLabelText?: never;
        // added null since undefined will cause issues with empty case
        initialValue?: Option[] | null;
        options: Option[];
        optionLabel: string;
        optionValue: string;
        onNewValue?: (newValue: string) => Promise<string>;
        style?: CSSProperties;
        accepts?: never;
        displayIntVales?: never;
        disable?: boolean;
        multi?: boolean;
        onDelete?: never;
        content?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
        onClose?: () => void;
        onOpen?: () => void
        /**
         * @param onValueChange
         * This handler will be used in addition to the internal onChange which is setting field value in the form state
         */
        onValueChange?: (args: Omit<FieldProps, "meta">, value: Option[]) => void;
        /**
         * @param onTextValueChange
         * (multi should be false) This handler will be used in addition to the internal onChange of TextField which can be used to do BE search on-type --- suggestion : please consider debouncing ---
         */
        onTextValueChange?: (value?: string) => void;
      }
    | {
        type: "phone";
        attributes: TextFieldProps;
        validationSchema: (values: FormikValues) => any;
        labelText: string;
        subLabelText?: never;
        initialValue: string | PhoneOtpObject;
        options?: never;
        optionLabel?: never;
        multi?: never;
        style?: CSSProperties;
        onNewValue?: never;
        displayIntVales?: never;
        disable?: never;
        optionValue?: never;
        accepts?: never;
        onDelete?: never;
        content?: never;
        onClose?: never;
        onOpen?: never;
        displayOnlyImages?: never;
        description?: string;
        defaultValidationError?: never;
        onReadOnlyDisplay?: never;
      };
};

type Flags = { [key: string]: boolean | PartialUpdateFlags };

type PartialUpdateFlags =
  | {
      isPartialUpdate: true;
      protectedField?: string[];
    }
  | {
      isPartialUpdate: false;
      protectedField?: never;
    };

interface PartialUpdateValues {
  [key: string]: any;
}

type useFormGeneratorProps = {
  title: string;
  subTitle?: string;
  config: FormGenConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: FormikValues) => Promise<any>;
  onClose: () => void;
  isLoading: boolean;
  /**
   *  @param isFetching  needs    FormLoadingSkeleton  to be provided
   */
  isFetching?: boolean;
  /**
   *  @param FormLoadingSkeleton  needs    isFetching  to be provided
   */
  FormLoadingSkeleton?: () => React.JSX.Element;
  portalRef?: MutableRefObject<null | HTMLDivElement>;
  insideRef?: MutableRefObject<null | HTMLDivElement>;
  debug?: ("initialValues" | "values" | "errors")[];
  noResetButton?: boolean;
  buttons?: { submit: JSX.Element | string; reset: JSX.Element | string };
  formStyle?: CSSProperties;
  readOnlyFormStyle?: boolean;
  noReadOnlySubmitButton?: boolean;
  forceOpenState?: boolean;
  flags?: Flags;
  type: "modal" | "drawer" | "none";
  drawerFormStyle?: SxProps<Theme>;
  noContainerFormStyle?: CSSProperties;
};

export const modalStyle: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "#fff",
  boxShadow: 24,
  padding: 4,
  borderRadius: 1,
  overflowY: "scroll",
  maxHeight: "90%",
};

const drawerBoxStyle: SxProps = {
  height: "100%",
  maxWidth: "32vw",
};

export const ParentJSX = ({
  onClose: configOnClose,
  type: configType,
  open,
  setOpen,
  children,
  isLoading,
  drawerFormStyle,
  noContainerFormStyle,
}: useFormGeneratorProps & {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) => {
  const onClose = useCallback(() => {
    if (isLoading) return;
    configOnClose();
    setOpen(false);
  }, [configOnClose, setOpen, isLoading]);

  return configType === "modal" ? (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>{children}</Box>
    </Modal>
  ) : configType === "drawer" ? (
    <Drawer anchor="right" onClose={onClose} open={open}>
      <Box sx={{ ...drawerBoxStyle, ...drawerFormStyle }}>{children}</Box>
    </Drawer>
  ) : (
    <div style={{ width: "100%", height: "100%", padding: "1rem", ...noContainerFormStyle }}>
      {children}
    </div>
  );
};

type initialValues = { value: any; config: string }[];
const useFormGenerator = ({
  title,
  subTitle = "",
  config: genConfig,
  onSubmit: configOnSubmit,
  onClose: configOnClose,
  type: configType,
  isLoading,
  isFetching,
  FormLoadingSkeleton,
  portalRef,
  insideRef,
  debug,
  buttons,
  noResetButton,
  formStyle,
  drawerFormStyle,
  readOnlyFormStyle,
  noReadOnlySubmitButton,
  forceOpenState,
  flags = {
    readOnly: false,
    partialUpdate: {
      isPartialUpdate: false,
    },
  },
  noContainerFormStyle,
}: useFormGeneratorProps) => {
  const { formatMessage: __ } = useIntl();
  const [open, setOpen] = useState<boolean>(configType === "none");
  const locale = useSelector((state: RootState) => state.language.locale);
  const [initialValuesState, setInitialValuesState] = useState<initialValues>([]);

  useEffect(() => {
    const newInitialValues = Object.entries(genConfig)
      .map(
        e =>
          e[1].type !== "label" && {
            value: e[1].initialValue,
            config: e[0],
          }
      )
      .filter(Boolean);

    if (!_.isEqual(newInitialValues, initialValuesState))
      setInitialValuesState(newInitialValues as initialValues);
  }, [genConfig]);

  const initialValuesParsed = useMemo(() => initialValuesState, [initialValuesState]);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const sharedInputsStyle = useRef<CSSProperties>({
    padding: "8px",
    margin: "dense",
  });

  useEffect(() => {
    debug?.includes("initialValues") &&
      console.log({
        initialValues: initialValuesParsed,
      });
  }, [debug, initialValuesParsed]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const toggleModal = useCallback(() => setOpen(prev => !prev), []);

  const validations = useMemo(
    () =>
      Object.entries(genConfig).reduce((prev, [name, { validationSchema }]) => {
        if (typeof validationSchema === "function") {
          return Object.assign(prev, { [name]: validationSchema() });
        }
        return prev; // Skip entries where validationSchema is not a function
      }, {}),
    [genConfig]
  );

  const validation = useMemo(() => {
    const filteredValidations: Record<string, Schema> = Object.entries(validations)
      .filter(([_, value]) => value !== null)
      .reduce((obj, [key, value]) => {
        // @ts-ignore
        obj[key] = value as Schema;
        return obj;
      }, {});

    return yupObject().shape(filteredValidations);
  }, [validations]);

  const componentJSX = useMemo(
    () => (
      <div>
        {!!title && (
          <Box
            sx={{
              backgroundColor: configType === "drawer" ? theme.palette.customGrey.dark : "inherit",
              padding: configType === "drawer" ? "21px 24px" : 0,
              borderBottom: configType === "drawer" ? "1px solid #EEE" : "none",
              boxShadow: configType === "drawer" ? "0 2px 4px #fafafa" : "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography
                variant={configType === "drawer" ? "h5" : "h3"}
                color={configType === "drawer" ? "default" : "primary"}
                fontWeight={700}
              >
                {title}
              </Typography>
              <Typography variant="subtitle2" color="gray">
                {subTitle}
              </Typography>
            </Box>
            {configType !== "none" && (
              <ButtonBase
                onClick={() => {
                  if (isLoading) return;
                  setOpen(false);
                  configOnClose();
                }}
              >
                <FontAwesomeIcon icon={faClose} fontSize={18} />
              </ButtonBase>
            )}
          </Box>
        )}

        <Box sx={{ padding: configType === "none" ? "2rem 0" : 3 }}>
          <Formik
            onSubmit={async (values, { resetForm }) => {
              try {
                if (
                  flags.partialUpdate &&
                  typeof flags.partialUpdate !== "boolean" &&
                  flags.partialUpdate.isPartialUpdate
                ) {
                  const partialUpdateValues = Object.entries(initialValuesParsed).reduce(
                    (data, [_key, item]) => {
                      if (item && item.config) {
                        const referenceValue = values[item.config as keyof typeof values];
                        if (
                          JSON.stringify(item.value) !== JSON.stringify(referenceValue) ||
                          (typeof flags.partialUpdate !== "boolean" &&
                            flags.partialUpdate?.isPartialUpdate &&
                            flags.partialUpdate.protectedField &&
                            flags.partialUpdate?.protectedField?.includes(
                              item.config as keyof typeof values
                            ))
                        ) {
                          data[item.config] = referenceValue;
                        }
                      }
                      return data;
                    },
                    {} as PartialUpdateValues
                  );
                  await configOnSubmit(partialUpdateValues);
                } else {
                  await configOnSubmit(values);
                }
                configType !== "none" && setOpen(false);
                configType !== "none" && configOnClose();
                resetForm();
              } catch (error) {
                console.log("error");
                console.error(error);
              }
            }}
            validationSchema={validation}
            initialValues={Object.entries(genConfig).reduce(
              (prev, [name, { initialValue, type }]) =>
                type !== "label" ? Object.assign(prev, { [name]: initialValue }) : prev,
              {}
            )}
          >
            {({
              errors,
              values,
              touched,
              setTouched,
              setValues,
              isSubmitting: formikIsSubmitting,
              isValid: formIsValid,
              ...others
            }: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              errors: FormikErrors<any>;
              values: FormikValues;
              touched: FormikTouched<any>;
              setTouched: (
                touched: FormikTouched<any>,
                shouldValidate?: boolean | undefined
              ) => Promise<void | FormikErrors<any>>;
              handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
              handleReset: () => void;
              setValues: (values: FormikValues) => void;
              isSubmitting: boolean;
              isValid: boolean;
            }) => {
              withFormikDevtools({
                errors,
                values,
                touched,
                setTouched,
                setValues,
                isSubmitting: formikIsSubmitting,
                ...others,
              });

              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                setValues(
                  Object.entries(genConfig).reduce(
                    (prev, [name, { initialValue, type }]) =>
                      type !== "label" ? Object.assign(prev, { [name]: initialValue }) : prev,
                    {}
                  )
                );
              }, [initialValuesParsed]);

              debug?.includes("values") && console.log({ values });
              debug?.includes("errors") && console.log({ errors });
              // eslint-disable-next-line react-hooks/rules-of-hooks
              return (
                <Form
                  style={{
                    display: "flex",
                    gap: "1rem 10px",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    ...formStyle,
                  }}
                >
                  {Object.entries(genConfig).map(
                    (
                      [
                        name,
                        {
                          attributes,
                          labelText: configLabelText,
                          type,
                          options,
                          initialValue,
                          optionLabel,
                          optionValue,
                          style,
                          onNewValue,
                          description,
                          accepts,
                          content,
                          multi = true,
                          displayIntVales,
                          displayOnlyImages,
                          onReadOnlyDisplay,
                          disable,
                          onDelete,
                          subLabelText,
                          defaultValidationError,
                          onClose,
                          onOpen,
                          // HINT: this is ignored since it requires adding this prop to all the types in InputsObj type which is unnecessary
                          // @ts-ignore
                          onChange,
                          // @ts-ignore
                          onValueChange,
                          // @ts-ignore
                          onTextValueChange,
                        },
                      ],
                      index
                    ) => {
                      const requiredLabel = `${configLabelText}${
                        isRequiredField(validation, name) ? " *" : ""
                      }`;

                      const labelText = onReadOnlyDisplay ? (
                        requiredLabel
                      ) : (
                        <>
                          {requiredLabel}
                          {description && (
                            <Tooltip title={description} arrow>
                              <FontAwesomeIcon
                                color={theme.palette.primary.main}
                                icon={faCircleInfo}
                                size="xs"
                                style={{ margin: "4px 0px 0px 4px" }}
                              />
                            </Tooltip>
                          )}
                        </>
                      );

                      const placeholder = attributes?.placeholder
                        ? attributes?.placeholder
                        : configLabelText
                        ? `${__(messages.startTypingYour)} ${configLabelText?.toLowerCase()}`
                        : "";

                      const placeholderSelect = attributes?.placeholder
                        ? attributes?.placeholder
                        : configLabelText
                        ? `${configLabelText?.toLowerCase()}`
                        : "";
                      return (
                        <div
                          key={`input-holder-form-generator-${name}`}
                          style={
                            type !== "hidden"
                              ? {
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  marginTop: 0,
                                  flexWrap: "wrap",
                                  position: "relative",
                                  ...style,
                                }
                              : { display: "none" }
                          }
                        >
                          {flags.readOnly && type !== "label" ? (
                            <>
                              <Field name={name} id={name} key={`generated-input-${name}`}>
                                {({
                                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                }: FieldProps) => {
                                  // eslint-disable-next-line react-hooks/rules-of-hooks
                                  return (
                                    <>
                                      <ReadOnlyDisplay
                                        labelText={labelText as string}
                                        type={type}
                                        values={values[name]}
                                        options={options}
                                        description={description}
                                        onReadOnlyDisplay={
                                          typeof onReadOnlyDisplay === "function"
                                            ? cloneElement(
                                                onReadOnlyDisplay({
                                                  form,
                                                }),
                                                { name }
                                              )
                                            : null
                                        }
                                        asFormStyle={readOnlyFormStyle}
                                      />
                                    </>
                                  );
                                }}
                              </Field>
                            </>
                          ) : (
                            <>
                              <Field name={name} id={name} key={`generated-input-${name}`}>
                                {({
                                  field, // { name, value, onChange, onBlur }
                                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                }: FieldProps) => {
                                  // eslint-disable-next-line react-hooks/rules-of-hooks
                                  return (
                                    <>
                                      {type === "colorPicker" ? (
                                        <ColorPickerComponent
                                          id={`form-generator-${name}`}
                                          change={(args: { currentValue: { hex: string } }) =>
                                            form.setFieldValue(name, args.currentValue.hex)
                                          }
                                          onBlur={() => {
                                            setTouched({ ...touched, [name]: true });
                                          }}
                                          value={values[name]}
                                          disabled={
                                            typeof flags.readOnly === "boolean"
                                              ? flags.readOnly
                                              : false
                                          }
                                        />
                                      ) : type === "datetime" ? (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                          adapterLocale={dayjs.locale(locale)}
                                        >
                                          <DateTimePicker
                                            {...attributes}
                                            minDateTime={
                                              attributes?.minDateTime
                                                ? dayjs(attributes?.minDateTime)
                                                : null
                                            }
                                            maxDateTime={
                                              attributes?.maxDateTime
                                                ? dayjs(attributes?.maxDateTime)
                                                : null
                                            }
                                            value={values[name] ? dayjs(values[name]) : null}
                                            timezone="system"
                                            label={labelText}
                                            slotProps={{
                                              layout: {
                                                sx: {
                                                  border: `solid 1px #F9F9F9`,
                                                  boxShadow: "0 0 2px #CCC",
                                                  fontWeight: 600,
                                                },
                                              },
                                              textField: {
                                                size: "small",
                                                InputLabelProps: {
                                                  sx: {
                                                    fontWeight: 600,
                                                  },
                                                },
                                              },
                                            }}
                                            onChange={(value: Dayjs | null) => {
                                              if (!touched[name]) {
                                                setTouched({ ...touched, [name]: true });
                                              }
                                              form.setFieldValue(name, value);
                                            }}
                                            onError={error => {
                                              setTouched({ ...touched, [name]: true });
                                              error && form.setFieldError(name, error);
                                            }}
                                          />
                                        </LocalizationProvider>
                                      ) : type === "date" ? (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                          adapterLocale={dayjs.locale(locale)}
                                        >
                                          <DatePicker
                                            {...attributes}
                                            slotProps={{
                                              layout: {
                                                sx: {
                                                  border: `solid 1px #F9F9F9`,
                                                  boxShadow: "0 0 2px #CCC",
                                                },
                                              },
                                              textField: {
                                                size: "small",
                                                InputLabelProps: {
                                                  shrink: true,
                                                  sx: { fontWeight: 600 },
                                                },
                                                sx: {
                                                  flexGrow: 1,
                                                  "& legend": {
                                                    fontWeight: 600,
                                                  },
                                                },
                                              },
                                            }}
                                            value={
                                              values[name]
                                                ? dayjs(values[name])
                                                    ?.set("hour", 0)
                                                    .set("minute", 0)
                                                    .set("second", 0)
                                                : null
                                            }
                                            label={labelText}
                                            onChange={(value: Dayjs | null) => {
                                              if (!touched[name]) {
                                                setTouched({ ...touched, [name]: true });
                                              }
                                              form.setFieldValue(name, value);
                                            }}
                                            onError={error => {
                                              setTouched({ ...touched, [name]: true });
                                              error && form.setFieldError(name, error);
                                            }}
                                            localeText={{
                                              fieldDayPlaceholder: () =>
                                                locale === "en-US" ? "DD" : "JJ",
                                              fieldYearPlaceholder: () =>
                                                locale === "en-US" ? "YYYY" : "AAAA",
                                            }}
                                          />
                                        </LocalizationProvider>
                                      ) : type === "toggle" ? (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              {...field}
                                              onBlur={() => {
                                                setTouched({ ...touched, [name]: true });
                                              }}
                                              checked={values[name]}
                                              onChange={(event, checked) =>
                                                event && form.setFieldValue(name, checked)
                                              }
                                            />
                                          }
                                          label={labelText}
                                        />
                                      ) : type === "textarea" ? (
                                        <TextField
                                          id={`textarea-${name}`}
                                          size="small"
                                          label={labelText}
                                          placeholder={placeholder}
                                          multiline
                                          fullWidth
                                          {...attributes}
                                          {...field}
                                          InputLabelProps={{
                                            shrink: true,
                                            sx: { fontWeight: 600 },
                                          }}
                                        />
                                      ) : type === "multi" ? (
                                        <AutocompleteMui
                                          {...attributes}
                                          {...sharedInputsStyle.current}
                                          {...field}
                                          multiple
                                          id={`multi-${name}`}
                                          options={options ? options : []}
                                          getOptionLabel={option =>
                                            optionLabel
                                              ? //@ts-ignore
                                                option[optionLabel]
                                              : ""
                                          }
                                          onBlur={() => {
                                            setTouched({ ...touched, [name]: true });
                                          }}
                                          defaultValue={[...initialValue]}
                                          onChange={async (
                                            event,
                                            value: (
                                              | { [key: string]: string }
                                              | { newValueAdd: string }
                                            )[]
                                          ) => {
                                            try {
                                              if (
                                                optionValue && // redundancy
                                                value?.at(-1)?.newValueAdd &&
                                                !value.some(
                                                  (e: { [key: string]: string }) =>
                                                    e[optionValue] === value?.at(-1)?.newValueAdd
                                                )
                                              ) {
                                                const newTagId =
                                                  onNewValue &&
                                                  (await onNewValue(
                                                    (
                                                      value.at(-1) as {
                                                        newValueAdd: string;
                                                      }
                                                    ).newValueAdd
                                                  ));

                                                event &&
                                                  optionValue &&
                                                  optionLabel &&
                                                  form.setFieldValue(name, [
                                                    ...value.filter(
                                                      e => !Object.keys(e).includes("newValueAdd")
                                                    ),
                                                    {
                                                      [optionValue]: newTagId,
                                                      [optionLabel]: (
                                                        value.at(-1) as {
                                                          newValueAdd: string;
                                                        }
                                                      ).newValueAdd,
                                                    },
                                                  ]);
                                              } else {
                                                event &&
                                                  optionValue &&
                                                  form.setFieldValue(name, [...value]);
                                              }
                                            } catch (error) {
                                              console.error(error);
                                              throw error;
                                            }
                                          }}
                                          isOptionEqualToValue={(
                                            option: { [key: string]: string },
                                            value: { [key: string]: string }
                                          ) =>
                                            optionLabel &&
                                            option[optionLabel] === value[optionLabel]
                                          }
                                          filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some(
                                              option =>
                                                inputValue ===
                                                //@ts-ignore
                                                option[optionLabel]
                                            );
                                            if (inputValue !== "" && !isExisting) {
                                              optionLabel &&
                                                filtered.push({
                                                  [optionLabel]: `${inputValue}`,
                                                  newValueAdd: `${inputValue}`,
                                                });
                                            }

                                            return filtered;
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              variant="standard"
                                              label={labelText}
                                              placeholder={placeholder}
                                            />
                                          )}
                                        />
                                      ) : type === "select" ? (
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id={`select-${name}-label`}
                                            size="small"
                                            sx={{
                                              fontWeight: 600,
                                            }}
                                            {...attributes}
                                            shrink={true}
                                            notched={true}
                                            variant="outlined"
                                          >
                                            {labelText}
                                          </InputLabel>
                                          <Select
                                            variant="outlined"
                                            size="small"
                                            {...attributes}
                                            {...field}
                                            labelId={`select-${name}-label`}
                                            id={`select-${name}-label`}
                                            IconComponent={null}
                                            required={Boolean(attributes?.isRequired)}
                                            endAdornment={
                                              <FontAwesomeIcon
                                                icon={faAngleDown}
                                                fontSize={14}
                                                style={{ cursor: "pointer" }}
                                              />
                                            }
                                            label={labelText}
                                            onChange={event => {
                                              form.setFieldValue(name, event.target.value);
                                              onChange &&
                                                onChange(
                                                  { field, form },
                                                  event.target.value as string | undefined
                                                );
                                            }}
                                            value={values[name]}
                                            onBlur={() => {
                                              setTouched({ ...touched, [name]: true });
                                            }}
                                            MenuProps={{
                                              PaperProps: {
                                                style: {
                                                  maxHeight: 200,
                                                },
                                              },
                                            }}
                                            shrink={true}
                                            notched={true}
                                            sx={{
                                              "& .MuiSelect-select .notranslate::after":
                                                placeholderSelect
                                                  ? {
                                                      content: `"${placeholderSelect}"`,
                                                      opacity: 0.42,
                                                    }
                                                  : {},
                                            }}
                                          >
                                            {options?.map((_optionValue, optionValueIndex) => (
                                              <MenuItem
                                                value={
                                                  optionValue
                                                    ? String(
                                                        _optionValue[
                                                          optionValue as keyof typeof _optionValue
                                                        ]
                                                      )
                                                    : String(_optionValue.value)
                                                }
                                                key={`select-${name}-label-item-${String(
                                                  _optionValue
                                                )}-${optionValueIndex}`}
                                              >
                                                {optionLabel
                                                  ? String([
                                                      _optionValue[
                                                        optionLabel as keyof typeof _optionValue
                                                      ],
                                                    ])
                                                  : String(_optionValue.text)}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      ) : type === "text" ||
                                        type === "number" ||
                                        type === "password" ? (
                                        <TextField
                                          size="small"
                                          type={type}
                                          {...field}
                                          {...attributes}
                                          fullWidth
                                          label={labelText}
                                          sx={{
                                            "& .MuiTypography-root.MuiTypography-subtitle1": {
                                              fontSize: "12px",
                                              fontWeight: 400,
                                              color: theme.palette.error.main,
                                            },
                                            "& legend": {
                                              fontWeight: 600,
                                            },
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                            sx: { fontWeight: 600 },
                                          }}
                                          placeholder={placeholder}
                                        />
                                      ) : type === "phone" ? (
                                        <PhoneCountryInput
                                          placeholder={placeholder}
                                          {...{ form, name, values, labelText, attributes, field }}
                                        />
                                      ) : type === "hidden" ? (
                                        <input
                                          type="hidden"
                                          style={sharedInputsStyle.current}
                                          {...field}
                                          // value={values[name]}
                                        />
                                      ) : type === "file" ? (
                                        <div>
                                          <FormControl
                                            onDrop={event =>
                                              form.setFieldValue(name, event.dataTransfer.files[0])
                                            }
                                            onDragOver={handleDragOver}
                                            fullWidth
                                          >
                                            <Input
                                              // {...field}
                                              style={{
                                                color: "transparent",
                                                ...sharedInputsStyle.current,
                                              }}
                                              id="file-upload"
                                              type="file"
                                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                form.setFieldValue(name, event?.target?.files?.[0]);
                                              }}
                                              inputProps={{
                                                accept: accepts?.join(","), // Optional: Limit accepted file types,
                                              }}
                                              value=""
                                            />
                                            {values[name] && <div>{values[name].name}</div>}
                                          </FormControl>
                                        </div>
                                      ) : type === "file2" ? (
                                        <FileUploader
                                          key={`file-uploader-form-${name}`}
                                          {...{
                                            name,
                                            values,
                                            form,
                                            multi,
                                            accepts: accepts as string[],
                                            displayIntVales,
                                            errors,
                                            attributes,
                                            displayOnlyImages,
                                            onDelete,
                                            style,
                                            labelText,
                                            subLabelText,
                                          }}
                                        />
                                      ) : type === "richtexteditor" ? (
                                        <CustomRichTextEditor
                                          hasError={!!touched[name] && !!errors[name]}
                                          errorText={String(errors[name])}
                                          onChange={value => form.setFieldValue(name, value)}
                                          initialValue={values[name]}
                                          key={`rich-text-editor-${name}`}
                                          onBlur={() => {
                                            setTouched({ ...touched, [name]: true });
                                          }}
                                          labelText={labelText as string}
                                        />
                                      ) : type === "checkbox" ? (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              {...attributes}
                                              checked={values[name]}
                                              onBlur={() => {
                                                setTouched({ ...touched, [name]: true });
                                              }}
                                              onChange={(_event, checked) => {
                                                form.setFieldValue(name, checked);
                                              }}
                                            />
                                          }
                                          label={labelText}
                                        />
                                      ) : type === "radiogroup" ? (
                                        <FormControl>
                                          <FormLabel id="radiogroup-id"> {labelText} </FormLabel>
                                          <RadioGroup
                                            defaultValue={initialValue}
                                            value={values[name]}
                                            onBlur={() => {
                                              setTouched({ ...touched, [name]: true });
                                            }}
                                            onChange={event => {
                                              form.setFieldValue(name, event.target.value);
                                            }}
                                          >
                                            {options?.map((option, index) => (
                                              <FormControlLabel
                                                key={`radio-option-${option.value}-${index}`}
                                                value={option.value}
                                                label={option.text}
                                                control={<Radio />}
                                              />
                                            ))}
                                          </RadioGroup>
                                        </FormControl>
                                      ) : type === "autocomplete" ? (
                                        <Autocomplete
                                          optionLabel={optionLabel as string}
                                          name={`autocomplete-${name}`}
                                          id={`autocomplete-${name}`}
                                          disabled={disable as boolean}
                                          submitCount={3}
                                          errors={errors as { [key: string]: string }}
                                          data={options as Option[]}
                                          selectedValue={
                                            multi
                                              ? [...(values[name] as Option[])]
                                              : (values[name] as Option)
                                          }
                                          onTextValueChange={onTextValueChange}
                                          label={labelText}
                                          multi={multi}
                                          setSelectedValue={(value: any) => {
                                            form.setFieldValue(name, multi ? [...value] : value);
                                            onValueChange && onValueChange({ field, form }, value);
                                          }}
                                          onClose={onClose}
                                          onOpen={onOpen}
                                          attributes={attributes}
                                          placeholder={placeholder}
                                        />
                                      ) : type === "tags" ? (
                                        <TagsInput
                                          key={`tags-input-form-${name}`}
                                          placeholder={placeholder}
                                          {...{ form, name, values, labelText, attributes }}
                                        />
                                      ) : type === "label" ? (
                                        <>
                                          {index !== 0 && (
                                            <Divider
                                              sx={{ margin: 0, marginY: "1rem", padding: 0 }}
                                            />
                                          )}
                                          {typeof content === "string" ? (
                                            <Typography variant={"h4"} style={{ margin: 0 }}>
                                              {content}
                                            </Typography>
                                          ) : (
                                            { content }
                                          )}
                                        </>
                                      ) : type === "customComponents" &&
                                        typeof content === "function" ? (
                                        cloneElement(content({ form, field }), { name })
                                      ) : null}
                                    </>
                                  );
                                }}
                              </Field>
                              {String(name) in errors &&
                              type !== "hidden" &&
                              type !== "file2" &&
                              type !== "richtexteditor" &&
                              type !== "phone" &&
                              defaultValidationError !== false
                                ? touched[name] && (
                                    <ErrorMessage>{String(errors[name])}</ErrorMessage>
                                  )
                                : ""}
                            </>
                          )}
                        </div>
                      );
                    }
                  )}
                  <div ref={insideRef} style={{ width: "100%" }}></div>
                  {flags.readOnly && noReadOnlySubmitButton ? (
                    <></>
                  ) : (
                    <InputWrapper
                      errors={errors}
                      buttons={[
                        !noResetButton
                          ? {
                              textButton: buttons?.reset || __(messages.resetBtn),
                              buttonProps: { type: "reset" },
                              buttonState: "secondary",
                              isLoading: false,
                            }
                          : undefined,
                        {
                          textButton: buttons?.submit || __(messages.saveBtn),
                          buttonProps: {
                            type: "submit",
                            variant: "contained",
                            disabled:
                              isLoading ||
                              JSON.stringify(values) === // prevent submit since nothing changed
                                JSON.stringify(
                                  Object.entries(genConfig).reduce(
                                    (prev, [name, { initialValue }]) =>
                                      Object.assign(prev, { [name]: initialValue }),
                                    {}
                                  )
                                ),
                          },
                          buttonState: "primary",
                          isLoading: isLoading,
                        },
                      ]}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </Box>
      </div>
    ),
    [genConfig, configOnSubmit, configOnClose, configType, isLoading, title, subTitle]
  );

  return {
    formJSX: (
      <ParentJSX
        open={forceOpenState ?? open}
        setOpen={setOpen}
        config={genConfig}
        onSubmit={configOnSubmit}
        onClose={configOnClose}
        type={configType satisfies useFormGeneratorProps["type"]}
        title={title}
        isLoading={isLoading}
        noContainerFormStyle={configType === "none" ? noContainerFormStyle : undefined}
        drawerFormStyle={configType === "none" ? undefined : drawerFormStyle}
      >
        {isFetching && FormLoadingSkeleton ? <FormLoadingSkeleton /> : componentJSX}
        <div ref={portalRef}></div>
      </ParentJSX>
    ),
    open,
    toggleModal,
    forceState: setOpen,
  };
};

// eslint-disable-next-line react-refresh/only-export-components
export default useFormGenerator;
